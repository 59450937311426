import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import Home from "./Home";
import NotFound from "./NotFound";
function App() {
  
  return(
    <>
    {/* <Home/> */}
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/404" element={<NotFound/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
    </>
  )
}

export default App;