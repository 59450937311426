import Typewriter from "./Typewriter";
import { useEffect } from "react";

const NotFound = () => {
    
      useEffect(() => {
        const blob = document.getElementById('blob');
    
        const handlePointerMove = (event) => {
          const { clientX, clientY } = event;
    
          blob.animate(
            {
              left: `${clientX}px`,
              top: `${clientY}px`,
            },
            { duration: 3000, fill: "forwards" }
          );
        };
    
        window.addEventListener("pointermove", handlePointerMove);
    
        return () => {
          window.removeEventListener("pointermove", handlePointerMove);
        };
      }, []);
    
      return (
        <>
          <div id="blob"></div>
          <div id="blur"></div>
          <h1 id="big">#404</h1>
          <Typewriter text="Definitely nothing here." delay={50} />
          
        </>
      );
};

export default NotFound;